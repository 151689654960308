import React from "react"

// Comps
import CanvasesHolder from "../../Canvases/CanvasHome/CanvasesHolder"
import CanvasMask from "../../Canvases/CanvasHome/CanvasMask"
import CanvasHome from "../../Canvases/CanvasHome/CanvasHome"

// Styles
import Panel404Styles from "./Panel404.module.styl"
import { Row, Cell } from "../../Layout/Grid"
import ButtonLink from "../../Buttons/ButtonLink"

const Panel404 = () => {
    return (
        <div className={Panel404Styles.Panel404}>
            <CanvasesHolder>
                {typeof window !== "undefined" && <CanvasHome />}
                {typeof window !== "undefined" && <CanvasMask />}
            </CanvasesHolder>
            <div className={Panel404Styles.PanelInner}>
                <Row>
                    <Cell start="0" end="12">
                        <div className={Panel404Styles.PanelTop}>
                            <h1 className="heading-1">Erreur 404</h1>
                        </div>
                        <div className={Panel404Styles.PanelBody}>
                            <p className="teasing-1">
                                Pas grand chose à voir ici, désolé.
                                <br />
                                Mais vous pouvez toujours jouer avec les petites
                                bulles si vous voulez.
                            </p>
                        </div>
                        <div className={Panel404Styles.PanelBottom}>
                            <ButtonLink title={`Retour à l'accueil`} to={"/"} />
                        </div>
                    </Cell>
                </Row>
            </div>
        </div>
    )
}

export default Panel404
