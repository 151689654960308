import React from "react"

class Canvas extends React.Component {
    constructor(props) {
        super(props)

        this.canvas = React.createRef()

        this.state = {
            width: window.innerWidth * window.devicePixelRatio,
            height: (window.innerHeight + 110) * window.devicePixelRatio,
        }

        this.ctx = null
    }

    componentDidMount() {
        this.setSizes()
        this.createContext()
        this.scaleCanvas()
    }

    componentDidUpdate() {
        this.setSizes()
    }

    bindEvents() {
        window.addEventListener("resize", () => this._resize())
        window.addEventListener("orientationchange", () => this._resize())
    }

    setSizes() {
        this.width = this.state.width / window.devicePixelRatio
        this.height = this.state.height / window.devicePixelRatio
    }

    createContext() {
        this.ctx = this.canvas.current.getContext("2d")
    }

    scaleCanvas() {
        this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    }

    _resize() {
        this.setState({
            width: window.innerWidth * window.devicePixelRatio,
            height: (window.innerHeight + 110) * window.devicePixelRatio,
        })

        this.scaleCanvas()
    }
}

export default Canvas
