import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { CustomContext } from "../../../context/CustomProvider"

// MISC
import meta from "../../../assets/images/meta.jpg"

// GraphQL
import { StaticQuery, graphql } from "gatsby"

const Metas = (props, { data }) => {
    const { isCookieConsented } = useContext(CustomContext)
    const cookieConsent =
        (typeof document !== "undefined" &&
            document.cookie.indexOf("Grow2k19=true") >= 0) ||
        isCookieConsented
            ? true
            : false
    const matomoScript = `var _paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
    var u="https://stats.spintank.fr/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '5']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();`
    const matomoNoScript = `<p><img src="https://stats.spintank.fr/matomo.php?idsite=5&amp;rec=1" style="border:0;" alt="" /></p>`

    return (
        <StaticQuery
            query={metaQuery}
            render={data => (
                <Helmet htmlAttributes={{ lang: "fr" }}>
                    <title>
                        {data.site.siteMetadata.title}{" "}
                        {props.title ? ` - ${props.title}` : ""}
                    </title>

                    <link
                        rel="canonical"
                        href={data.site.siteMetadata.siteUrl}
                    />
                    <meta
                        name="description"
                        content="30.11.2019 @ Le Tank, Paris – The first parisian event dedicated to advanced creative coding is back. Codename : GROW."
                    />
                    <meta
                        name="image"
                        content={`${data.site.siteMetadata.siteUrl}${meta}`}
                    />

                    <meta
                        itemprop="name"
                        content={data.site.siteMetadata.title}
                    />
                    <meta
                        itemprop="description"
                        content="30.11.2019 @ Le Tank, Paris – The first parisian event dedicated to advanced creative coding is back. Codename : GROW."
                    />
                    <meta
                        itemprop="image"
                        content={`${data.site.siteMetadata.siteUrl}${meta}`}
                    />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                        name="twitter:title"
                        content={data.site.siteMetadata.title}
                    />
                    <meta
                        name="twitter:description"
                        content={data.site.siteMetadata.description}
                    />
                    <meta name="twitter:site" content="@grow_paris" />
                    <meta
                        name="twitter:image"
                        content={`${data.site.siteMetadata.siteUrl}${meta}`}
                    />

                    <meta
                        property="og:title"
                        content={data.site.siteMetadata.title}
                    />
                    <meta
                        property="og:description"
                        content={data.site.siteMetadata.description}
                    />
                    <meta
                        property="og:url"
                        content={data.site.siteMetadata.siteUrl}
                    />
                    <meta
                        property="og:site_name"
                        content={data.site.siteMetadata.title}
                    />
                    <meta property="og:locale" content="fr_FR" />
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:image"
                        content={`${data.site.siteMetadata.siteUrl}${meta}`}
                    />
                    <script type="text/javascript">{`${
                        cookieConsent ? matomoScript : ""
                    }`}</script>
                    <noscript>{`${
                        cookieConsent ? matomoNoScript : ""
                    }`}</noscript>
                </Helmet>
            )}
        />
    )
}

export const metaQuery = graphql`
    query SiteMetadataLookup {
        site {
            siteMetadata {
                title
                siteUrl
                description
            }
        }
    }
`

export default Metas
