import React, { useContext, useEffect } from "react"
import { TransitionState } from "gatsby-plugin-transition-link"
import { CustomContext } from "../context/CustomProvider"
import Metas from "../components/Misc/Metas"
import Panel404 from "../components/Panels/Panel404"

const NotFoundPage = () => {
    const { setIsHome } = useContext(CustomContext)

    useEffect(() => {
        setIsHome(false)
    }, [])

    return (
        <>
            <Metas title="Erreur 404" />
            <TransitionState>
                {({ transitionStatus, exit, enter }) => {
                    return (
                        <div
                            className={`container container--404 is-${transitionStatus}`}
                        >
                            <Panel404 />
                        </div>
                    )
                }}
            </TransitionState>
        </>
    )
}

export default NotFoundPage
