import React from "react"

// Styles
import canvasHolderStyles from "./CanvasesHolder.module.styl"

const CanvasesHolder = ({ children }) => {
    return <div className={canvasHolderStyles.CanvasesHolder}>{children}</div>
}

export default CanvasesHolder
