const NumberUtils = {
    map(num, min1, max1, min2, max2) {
        let num1 = (num - min1) / (max1 - min1)
        let num2 = num1 * (max2 - min2) + min2

        return num2
    },

    hex2rgb(hex) {
        hex = hex.substr(0, 1) === "#" ? hex.substr(1) : hex
        return [
            parseInt(hex.substr(0, 2), 16),
            parseInt(hex.substr(2, 2), 16),
            parseInt(hex.substr(4, 2), 16),
        ]
    },

    toRadians(degree) {
        return degree * (Math.PI / 180)
    },

    toDegree(radians) {
        return radians * (180 / Math.PI)
    },

    dist(x1, y1, x2, y2) {
        let a = x1 - x2
        let b = y1 - y2

        let c = Math.sqrt(a * a + b * b)

        return c
    },

    getRandomNumber(min, max) {
        return Math.random() * (max - min) + min
    },

    getRandomFloorNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min)
    },

    valBetween(v, min, max) {
        return Math.min(max, Math.max(min, v))
    },

    lerp(start, end, amt) {
        return (1 - amt) * start + amt * end
    },
}

export default NumberUtils
