import React from "react"
import Canvas from "./Canvas"

import CanvasHomeStyles from "./CanvasHome.module.styl"

// Helpers
import NumberUtils from "../../../helpers/numberUtils"
import InCircleUtils from "../../../helpers/inCircleUtils"
import SptkUtils from "../../../helpers/sptk"

class CanvasHome extends Canvas {
    constructor(props) {
        super(props)

        this.mouse = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        }

        this.cursor = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        }

        this.cursorCurrentPos = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        }

        this.circleRadius = 0
        this.time = 0
        this.mouseIsMoving = false
    }

    componentDidMount() {
        super.componentDidMount()

        this.createPolygon()
        this.createTriangles()

        this.update = setInterval(this.draw.bind(this), 1000 / 40)
        this.drawCircleCursor()

        this.bindEvents()
    }

    componentWillUnmount() {
        clearInterval(this.update)
    }

    componentDidUpdate() {
        super.componentDidUpdate()

        this.createPolygon()
        this.createTriangles()
        this.drawCircleCursor()
    }

    bindEvents() {
        super.bindEvents()

        document.addEventListener("mousemove", e => this._onMouseMove(e))
    }

    createPolygon() {
        this.polygon = []

        const sides = NumberUtils.getRandomFloorNumber(8, 12)
        let R = 0

        if (this.width >= this.height) {
            R = this.height
        } else {
            R = this.width
        }

        const posX = this.width / 2
        const posY = this.height / 2

        for (let i = 0; i < sides; i++) {
            const angle = NumberUtils.map(i, 0, sides, 0, Math.PI * 2)

            const x =
                posX +
                Math.cos(angle) *
                    (R + NumberUtils.getRandomNumber(-R / 2, R / 2))
            const y =
                posY +
                Math.sin(angle) *
                    (R + NumberUtils.getRandomNumber(-R / 2, R / 2))

            this.polygon.push({
                x,
                y,
            })
        }
    }

    createTriangles() {
        this.triangles = []

        for (let i = 0, j = this.polygon.length; i < j; i++) {
            const x1 = this.polygon[i].x
            const y1 = this.polygon[i].y

            let x2, y2

            if (i !== j - 1) {
                x2 = this.polygon[i + 1].x
                y2 = this.polygon[i + 1].y
            } else {
                x2 = this.polygon[0].x
                y2 = this.polygon[0].y
            }

            this.triangles.push({
                x1,
                y1,
                x2,
                y2,
            })
        }
    }

    createCircles() {
        this.circles = []

        for (let i = 0, j = this.triangles.length; i < j; i++) {
            const currentTriangle = this.triangles[i]
            const positions = [
                {
                    x: this.cursor.x,
                    y: this.cursor.y,
                },
                {
                    x: currentTriangle.x1,
                    y: currentTriangle.y1,
                },
                {
                    x: currentTriangle.x2,
                    y: currentTriangle.y2,
                },
            ]

            const radius = InCircleUtils.inCircleRadius(positions)
            const center = InCircleUtils.inCirclePosition(positions)

            this.drawCircle({
                radius,
                center,
            })

            this.circles.push({
                radius,
                center,
            })
        }
    }

    drawCircle(properties) {
        this.ctx.beginPath()
        this.ctx.arc(
            properties.center.x,
            properties.center.y,
            properties.radius,
            0,
            2 * Math.PI
        )

        let checkDevice = new SptkUtils()

        if (checkDevice.utils.isMobile) {
            this.ctx.lineWidth = 5
        } else {
            this.ctx.lineWidth = 8
        }
        this.ctx.stroke()
        this.ctx.closePath()
    }

    drawCircleCursor() {
        if (!this.circles) {
            return
        }

        let minD = 99999999

        for (let i = 0, j = this.circles.length; i < j; i++) {
            let currentCircle = this.circles[i]

            const x1 = currentCircle.center.x
            const y1 = currentCircle.center.y
            const x2 = this.cursor.x
            const y2 = this.cursor.y

            const d = NumberUtils.dist(x1, y1, x2, y2)
            const deltaD = d - currentCircle.radius

            if (deltaD < minD) {
                minD = deltaD
            }
        }

        this.currentCircleRadius = minD

        if (this.circleRadius < 0) {
            this.circleRadius = 0
        }

        this.ctx.beginPath()
        this.ctx.arc(
            this.cursor.x,
            this.cursor.y,
            this.circleRadius,
            0,
            2 * Math.PI * 2
        )
        this.ctx.stroke()
        this.ctx.closePath()
    }

    draw() {
        this.ctx.clearRect(0, 0, this.width, this.height)
        this.createCircles()

        this.cursor.x = NumberUtils.lerp(
            this.cursor.x,
            this.cursorCurrentPos.x,
            0.05
        )
        this.cursor.y = NumberUtils.lerp(
            this.cursor.y,
            this.cursorCurrentPos.y,
            0.05
        )

        this.drawCircleCursor()

        this.circleRadius = this.currentCircleRadius

        if (Math.round(this.cursorCurrentPos.x) == Math.round(this.cursor.x)) {
            this.mouseIsMoving = false
        }

        if (!this.mouseIsMoving) {
            this.time += 0.005
            this.cursorCurrentPos.x =
                this.width / 2 +
                200 *
                    (Math.sin(this.time) /
                        (1 + Math.cos(this.time) * Math.cos(this.time)))
            this.cursorCurrentPos.y =
                this.height / 2 +
                200 *
                    ((Math.sin(this.time) * Math.cos(this.time)) /
                        (1 + Math.cos(this.time) * Math.cos(this.time)))
        }
    }

    _onMouseMove(e) {
        this.mouseIsMoving = true

        this.mouse.x = e.pageX
        this.mouse.y = e.pageY

        this.cursorCurrentPos = {
            x: this.mouse.x,
            y: this.mouse.y,
        }
    }

    render() {
        return (
            <canvas
                className={CanvasHomeStyles.CanvasHome}
                ref={this.canvas}
                width={this.state.width}
                height={this.state.height}
            ></canvas>
        )
    }
}

export default CanvasHome
