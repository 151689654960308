import React from "react"
import Canvas from "./Canvas"

import CanvasMaskStyles from "./CanvasMask.module.styl"

// Helpers
import SptkUtils from "../../../helpers/sptk"

class CanvasMask extends Canvas {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        super.componentDidMount()

        this.drawMask()

        this.bindEvents()
    }

    componentDidUpdate() {
        super.componentDidUpdate()

        this.update = setTimeout(() => {
            this.drawMask()
        }, 1)
    }

    componentWillUnmount() {
        clearTimeout(this.update)
    }

    drawMask() {
        let radius = 0

        let checkDevice = new SptkUtils()

        if (checkDevice.utils.isMobile) {
            radius = this.height / 2.5 - 15
        } else {
            radius = this.height / 2 - 15
        }

        this.ctx.fillStyle = "#fff"
        this.ctx.fillRect(0, 0, this.width, this.height)
        this.ctx.save()

        this.ctx.globalCompositeOperation = "destination-out"

        this.ctx.beginPath()

        this.ctx.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            2 * Math.PI,
            false
        )

        this.ctx.fill()
        this.ctx.restore()
        this.ctx.beginPath()
        this.ctx.arc(
            this.width / 2,
            this.height / 2,
            radius + 5,
            0,
            2 * Math.PI,
            false
        )
        if (checkDevice.utils.isMobile) {
            this.ctx.lineWidth = 6
        } else {
            this.ctx.lineWidth = 8
        }
        this.ctx.stroke()
    }

    bindEvents() {
        super.bindEvents()
    }

    render() {
        return (
            <canvas
                className={CanvasMaskStyles.CanvasMask}
                ref={this.canvas}
                width={this.state.width}
                height={this.state.height}
            ></canvas>
        )
    }
}

export default CanvasMask
