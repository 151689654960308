import NumberUtils from "./numberUtils"

const inCircleUtils = {
    inCircleRadius(positions) {
        const a = NumberUtils.dist(
            positions[2].x,
            positions[2].y,
            positions[1].x,
            positions[1].y
        )
        const b = NumberUtils.dist(
            positions[1].x,
            positions[1].y,
            positions[0].x,
            positions[0].y
        )
        const c = NumberUtils.dist(
            positions[0].x,
            positions[0].y,
            positions[2].x,
            positions[2].y
        )

        const s = (a + b + c) / 2

        const r = Math.sqrt(s * (s - a) * (s - b) * (s - c)) / s

        return r
    },

    inCirclePosition(positions) {
        //TRIANGLE SEGMENTS
        const a = NumberUtils.dist(
            positions[0].x,
            positions[0].y,
            positions[1].x,
            positions[1].y
        )
        const b = NumberUtils.dist(
            positions[1].x,
            positions[1].y,
            positions[2].x,
            positions[2].y
        )
        const c = NumberUtils.dist(
            positions[0].x,
            positions[0].y,
            positions[2].x,
            positions[2].y
        )

        const P = a + b + c

        const A = {
            x: positions[2].x,
            y: positions[2].y,
        }

        const B = {
            x: positions[0].x,
            y: positions[0].y,
        }

        const C = {
            x: positions[1].x,
            y: positions[1].y,
        }

        const O = {
            x: (A.x * a + B.x * b + C.x * c) / P,
            y: (A.y * a + B.y * b + C.y * c) / P,
        }

        return O
    },
}

export default inCircleUtils
